import React, { Component, useState, useEffect } from 'react';
import { Container, Button, Box, Avatar, Typography, TextField, Grid, Link, Paper, CircularProgress } from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import { useNavigate } from 'react-router-dom';
import { ErrorAlertTopCenter } from '../../common/snackbars';

import { forgotPassword } from '../../services/http.service';
import logger from '../../utils/logger';
import { getErrorMsg } from '../../utils/constants';


const ForgotPassword = () => {	

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    const navigate = useNavigate();


    /**
     * Handle submit
     */
    const handleSubmit = (e) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        const mobile = data.get('mobile');

        logger.info('Forgot password form submitted', { mobile });

        setIsSubmitting(true);
        setShowSuccessAlert(false);
        setErrorMsg(null);
        forgotPassword(mobile).then(resp => {
            setIsSubmitting(false);
            logger.info('Forgot password request successful', { response: resp.data });
            if (resp.data.data) {
                navigate('/forgot-password/otp-verification/' + resp.data.data.user_id);
            }
        }).catch(err => {
            logger.error('Forgot password request failed', err);
            setIsSubmitting(false);
            setShowSuccessAlert(false);
            setErrorMsg(getErrorMsg(err));
        });
    }

    return (
        <>
            { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
             <Container component="main" maxWidth="xs">
                <Paper elevation={10}>
                    <Box
                        padding={2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockResetIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Forgot password
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, px: 2, width: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="mobile"
                                label="Mobile/Email"
                                name="mobile"
                                autoComplete="mobile"
                                autoFocus
                            />

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSubmitting &&
                                    <CircularProgress />
                                }
                                {!isSubmitting &&
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Submit
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Container>            
        </>
    );
}

export default ForgotPassword;