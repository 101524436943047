import React, { useState, useEffect, useRef } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Typography, Chip, StepContent, Paper, Grid,
    TextField, Autocomplete, CircularProgress } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';

import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import { DEFAULT_MAP_LOCATION } from '../../../../utils/constants';
import { getStudent, fetchSchool as fetchSchoolHttp } from '../../../../services/http.service';

import StudentBasicDetail from './basic_detail';
import StudentPhoto from './photo';
import StudentLocation from './location';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'NewStudent';  // Define logTag for this component

const steps = [
    {
        label: 'Basic information',
        description: `Enter basic details of the student`,
    },
    {
        label: 'Profile photo',
        description: `Upload student photo`,
    },
    // {
    //     label: 'Add Pickup address (Optional)',
    //     description:
    //         "Add student's pickup address on the Map from where the school bus picks the student up daily for school.",
    // },
    // {
    //     label: 'Add Drop-off address (Optional)',
    //     description: `This is optional. In case the student's drop-off location is different from the pickup location. The default is the pickup location.`,
    // },
];

const NewStudent = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [student, setStudent] = useState({
        id: null,
        name: '',
        rollNo: '',
        sclass: {},
        section: {},
        address: {
            address: '',
            city: '',
            state: '',
            country: '',
            postalCode: ''
        },
        pickup: {
            latitude: DEFAULT_MAP_LOCATION.latitude,
            longitude: DEFAULT_MAP_LOCATION.longitude,
            location: '',
            distanceAndDuration: null, // from school location
        },
        dropoff: {
            latitude: null,
            longitude: null,
            location: '',
            distanceAndDuration: null, // from school location
        }, 
        isDropoffSameAsPickup: false,               
    });
    const [studentLoading, setStudentLoading] = useState(false);
    const [school, setSchool] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    const navigate = useNavigate();
    const { studentId } = useParams();
    const schoolId = useSelector(state => state.extras.schoolId);

    useEffect(() => {
        logger.info('NewStudent component mounted', { logTag, studentId });  // Log component mount
        if (studentId) {
            fetchStudent(studentId);
        }
        fetchSchool();
    }, [studentId]);

    // Fetch student detail for edit
    const fetchStudent = (studentId) => {
        logger.info('Fetching student details', { logTag, studentId });  // Log fetching student
        setStudentLoading(false);
        getStudent(studentId).then(resp => {
            setStudentDetail(resp.data.data);
            logger.info('Student details fetched successfully', { logTag, studentId });  // Log success
        }).catch(err => {
            logger.error('Error fetching student details', err, { logTag, studentId });  // Log error
        });
    }

    // fetch school from server
    const fetchSchool = () => {
        logger.info('Fetching school details', { logTag, schoolId });  // Log fetching school
        fetchSchoolHttp(schoolId).then(resp => {
            const respSchool = resp.data.school;
            setSchool(respSchool);
            logger.info('School details fetched successfully', { logTag, schoolId });  // Log success
        }).catch(err => {
            logger.error('Error fetching school details', err, { logTag, schoolId });  // Log error
        });
    }

    // set student detail in the state from server
    const setStudentDetail = (respStudent) => {
        let pickup = student.pickup;
        let dropoff = student.dropoff;

        // Update pickup details
        if (respStudent.pickup && respStudent.pickup.id) {
            pickup = {
                id: respStudent.pickup.id,
                location: respStudent.pickup.location,
                latitude: respStudent.pickup.latitude,
                longitude: respStudent.pickup.longitude
            }
        }

        // Update dropoff details
        if (respStudent.dropoff && respStudent.dropoff.id) {
            dropoff = {
                id: respStudent.dropoff.id,
                location: respStudent.dropoff.location,
                latitude: respStudent.dropoff.latitude,
                longitude: respStudent.dropoff.longitude
            }
        }

        setStudent((prevSchool) => ({
            ...prevSchool,
            id: respStudent.id,
            name: respStudent.name,
            rollNo: respStudent.roll_no,
            sclass: respStudent.sclass,
            section: respStudent.section,
            uniqueNumber: respStudent.unique_no,
            isDropoffSameAsPickup: respStudent.is_dropoff_same_as_pickup,
            profilePhoto: respStudent.profile_photo,
            pickup,
            dropoff,
        }));
        logger.info('Student details set in state', { logTag, studentId: respStudent.id });  // Log setting student state
    }

    // update student
    const updateStudent = (student) => {
        logger.info('Updating student state', { logTag, studentId: student.id });  // Log updating student state
        setStudent(student);
    }

    // ############ Stepper config ########################
    const handleNext = () => {
        logger.info('Next step clicked', { logTag, currentStep: activeStep });  // Log next step
        if (student && student.id) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            setErrorMsg("Please save user details first");
        }
    };

    const handleBack = () => {
        logger.info('Back step clicked', { logTag, currentStep: activeStep });  // Log back step
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        logger.info('Resetting steps', { logTag });  // Log reset
        setActiveStep(0);
    };
    // ############ End // Stepper config ########################

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => {
                        logger.info('Error alert closed', { logTag });  // Log alert close
                        setErrorMsg(null);
                    }}
                />
            }
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >   
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Add new student
                    </Typography>
                    {student && student.uniqueNumber &&
                        <Typography component="h5" variant="h5">
                            Student ID: <strong>{student.uniqueNumber}</strong>
                        </Typography>
                    }
                    <Chip
                        onClick={() => {
                            logger.info('Navigating to student list', { logTag });  // Log navigation to student list
                            navigate(`../list`);
                        }}
                        icon={<FormatListBulletedIcon />}
                        label="Students"
                        variant="outlined"
                        color="secondary"
                    />
                </Box>

                <Stepper activeStep={activeStep} orientation="vertical" sx={{ width: "100%" }}>
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel>
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                <Typography>{step.description}</Typography>
                                <Box sx={{ mb: 2 }}>
                                    {/* Student Basic information */}
                                    {index === 0 &&
                                        <StudentBasicDetail 
                                            onNext={handleNext} 
                                            student={student} 
                                            onSubmit={(student) => updateStudent(student)}
                                        />
                                    }
                                    {/* Student photo */}
                                    {index === 1 &&
                                        <StudentPhoto 
                                            // onNext={handleNext} 
                                            onBack={handleBack} 
                                            student={student} 
                                            onSubmit={(student) => updateStudent(student)}
                                        />
                                    }
                                    {/* Student pickup address */}
                                    {/* {index === 2 &&
                                        <StudentLocation 
                                            onNext={handleNext} 
                                            onBack={handleBack} 
                                            student={student}
                                            type={"pickup"}
                                            onSubmit={(student) => updateStudent(student)}
                                        />
                                    } */}
                                    {/* Student dropoff address */}
                                    {/* {index === 3 &&
                                        <StudentLocation 
                                            onNext={handleNext} 
                                            onBack={handleBack} 
                                            student={student}
                                            type={"dropoff"}
                                            onSubmit={(student) => updateStudent(student)}
                                        />
                                    } */}
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                            Reset
                        </Button>
                    </Paper>
                )}
            </Box>
        </>
    );
}

export default NewStudent;



// import React, { useState, useEffect, useRef } from 'react';
// import { Box, Stepper, Step, StepLabel, Button, Typography, Chip, StepContent, Paper, Grid,
//     TextField, Autocomplete, CircularProgress } from '@mui/material';
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';

// import { useParams, useNavigate } from "react-router-dom";
// import { useSelector } from 'react-redux';

// import { DEFAULT_MAP_LOCATION } from '../../../../utils/constants';
// import { getStudent, fetchSchool as fetchSchoolHttp } from '../../../../services/http.service';

// import StudentBasicDetail from './basic_detail';
// import StudentPhoto from './photo';
// import StudentLocation from './location';


// const steps = [
//     {
//         label: 'Basic information',
//         description: `Enter basic details of the student`,
//     },
//     {
//         label: 'Profile photo',
//         description: `Upload student photo`,
//     },
//     {
//         label: 'Add Pickup address',
//         description:
//             "Add student's pickup address on the Map from where school bus picks student up daily for school.",
//     },
//     {
//         label: 'Add Dropp off address (Optional)',
//         description: `This is optional. In case student's drop off location is different from pickup location. Default is pickup location`,
//     },
// ];

// const NewStudent = () => {
//     const [activeStep, setActiveStep] = React.useState(0);
//     const [student, setStudent] = useState({
//         id: null,
//         name: '',
//         rollNo: '',
//         sclass: {},
//         section: {},
//         address: {
//             address: '',
//             city: '',
//             state: '',
//             country: '',
//             postalCode: ''
//         },
//         pickup: {
//             latitude: DEFAULT_MAP_LOCATION.latitude,
//             longitude: DEFAULT_MAP_LOCATION.longitude,
//             location: '',
//             distanceAndDuration: null, // from school location
//         },
//         dropoff: {
//             latitude: null,
//             longitude: null,
//             location: '',
//             distanceAndDuration: null, // from school location
//         }, 
//         isDropoffSameAsPickup: false,               
//     });
//     const [studentLoading, setStudentLoading] = useState(false);
//     const [school, setSchool] = useState(null);
//     const [errorMsg, setErrorMsg] = useState(null);


//     const navigate = useNavigate();
//     const { studentId } = useParams();
//     const schoolId = useSelector(state => state.extras.schoolId);

//     useEffect(() => {
//         if (studentId) {
//             fetchStudent(studentId);
//         }
//         console.log('fetching students');
//         //fetchStudents(); 
//         fetchSchool();
//     }, []);

//     useEffect(() => {
//         console.log("useEffect student ", JSON.stringify(student));
//     }, [student]);

//     // Fetch student detail for edit
//     const fetchStudent = (studentId) => {
//         setStudentLoading(false);
//         getStudent(studentId).then(resp => {
//             console.log(`Fetch student - ${JSON.stringify(resp)}`);
//             setStudentDetail(resp.data.data);
//         }).catch(err => {
//             console.log(err);
//         })
//     }

//     // fetch school from server
//     const fetchSchool = () => {
//         fetchSchoolHttp(schoolId).then(resp => {
//             const respSchool = resp.data.school;
//             console.log(`loaded school - ${JSON.stringify(respSchool)}`);
//             //school = respSchool; // assigned to state school as state not changed previous school object
//             setSchool(respSchool);
//         }).catch(err => {
//             console.log(err);
//         })
//     }

//     // set student detail in the state from server
//     const setStudentDetail = (respStudent) => {
//         let pickup = student.pickup;
//         let dropoff = student.dropoff;

//         // Update pickup details
//         if (respStudent.pickup && respStudent.pickup.id) {
//             // do not use setStudent
//             pickup = {
//                 id: respStudent.pickup.id,
//                 location: respStudent.pickup.location,
//                 latitude: respStudent.pickup.latitude,
//                 longitude: respStudent.pickup.longitude
//             }
//             console.log(pickup);
//         }
//         // Update dropoff details
//         if (respStudent.dropoff && respStudent.dropoff.id) {
//             // do not use state setters
//             dropoff = {
//                 id: respStudent.dropoff.id,
//                 location: respStudent.dropoff.location,
//                 latitude: respStudent.dropoff.latitude,
//                 longitude: respStudent.dropoff.longitude
//             }
//         }
//         setStudent((prevSchool) => ({
//             ...prevSchool,
//             id: respStudent.id,
//             name: respStudent.name,
//             rollNo: respStudent.roll_no,
//             sclass: respStudent.sclass,
//             section: respStudent.section,
//             uniqueNumber: respStudent.unique_no,
//             isDropoffSameAsPickup: respStudent.is_dropoff_same_as_pickup,
//             profilePhoto: respStudent.profile_photo,
//             pickup,
//             dropoff,
//         }));
//         console.log(`Student in state from server - ${JSON.stringify(student)}`);
//     }

//     // update student
//     const updateStudent = (student) => {
//         console.log(student);
//         setStudent(student);
//     }



//     // ############ Stepper config ########################
//     const handleNext = () => {
//         // check if student already exists then move to next
//         if (student && student.id) {
//             setActiveStep((prevActiveStep) => prevActiveStep + 1);
//         }
//         else {
//             setErrorMsg("Please save user details first");
//         }
//     };

//     const handleBack = () => {
//         setActiveStep((prevActiveStep) => prevActiveStep - 1);
//     };
//     const handleReset = () => {
//         setActiveStep(0);
//     };
//     // ############ End // Stepper config ########################


//     return (
//         <>
//              {errorMsg &&
//                 <ErrorAlertTopCenter
//                     errorMsg={errorMsg}
//                     onClose={() => setErrorMsg(null)}
//                 />
//             }
//             <Box
//                 sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "left",
//                     alignItems: "start",
//                 }}
//             >   
//                 <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//                     <Typography component="h2" variant="header">
//                         Add new student
//                     </Typography>
//                     {student && student.uniqueNumber &&
//                         <Typography component="h5" variant="h5">
//                             Student ID: <strong>{student.uniqueNumber}</strong>
//                         </Typography>
//                     }
//                     <Chip
//                         onClick={() => navigate(`../list`)}
//                         icon={<FormatListBulletedIcon />}
//                         label="Students"
//                         variant="outlined"
//                         color="secondary"
//                     />
//                 </Box>

//                 <Stepper activeStep={activeStep} orientation="vertical" sx={{ width: "100%" }}>
//                     {steps.map((step, index) => (
//                         <Step key={step.label}>
//                             <StepLabel>
//                                 {step.label}
//                             </StepLabel>
//                             <StepContent>
//                                 <Typography>{step.description}</Typography>
//                                 <Box sx={{ mb: 2 }}>
//                                     {/* Student Basic information */}
//                                     {index === 0 &&
//                                         <StudentBasicDetail 
//                                             onNext={handleNext} 
//                                             student={student} 
//                                             onSubmit={(student) => updateStudent(student)}
//                                         />
//                                     }
//                                     {/* Student photo */}
//                                     {index === 1 &&
//                                         <StudentPhoto 
//                                             onNext={handleNext} 
//                                             onBack={handleBack} 
//                                             student={student} 
//                                             onSubmit={(student) => updateStudent(student)}
//                                         />
//                                     }
//                                     {/* Student pickup address */}
//                                     {index === 2 &&
//                                         <StudentLocation 
//                                             onNext={handleNext} 
//                                             onBack={handleBack} 
//                                             student={student}
//                                             type={"pickup"}
//                                             onSubmit={(student) => updateStudent(student)}
//                                         />
//                                     }
//                                     {/* Student pickup address */}
//                                     {index === 3 &&
//                                         <StudentLocation 
//                                             onNext={handleNext} 
//                                             onBack={handleBack} 
//                                             student={student}
//                                             type={"dropoff"}
//                                             onSubmit={(student) => updateStudent(student)}
//                                         />
//                                     }
//                                 </Box>
//                             </StepContent>
//                         </Step>
//                     ))}
//                 </Stepper>
//                 {activeStep === steps.length && (
//                     <Paper square elevation={0} sx={{ p: 3 }}>
//                         <Typography>All steps completed - you&apos;re finished</Typography>
//                         <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
//                             Reset
//                         </Button>
//                     </Paper>
//                 )}
//             </Box>
//         </>
//     );

// }

// export default NewStudent;
