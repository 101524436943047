import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Container, Button, Box, Avatar, Typography, TextField, Grid, Paper, CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import { ErrorAlertTopCenter } from '../../common/snackbars';

import { register } from '../../services/http.service';
import logger from '../../utils/logger';
import { getErrorMsg } from '../../utils/constants';

import '../../styles/register.css';

// Yup from validation
const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    mobile: Yup.string().required("Mobile is required."),
    email: Yup.string().required("Email is required.").email("Invalid email."),
    password: Yup.string().required("Password is required.")
        .min(6, "Password must be 6 chars long").max(32, "Password must be more that 32 chars long"),
    confirmPassword: Yup.string().required("Password is required.")
        .min(6, "Password must be 6 chars long").max(32, "Password must be more that 32 chars long")
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    
});

const Register = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    let navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
            mobile: '',
            email: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            logger.info('Submitting registration form', values);
            setIsSubmitting(true);
            setErrorMsg(null);
            register(values).then(resp => {
                logger.info(`Registration successful for user ${resp.data.user_id}`, resp.data);
                setIsSubmitting(false);
                navigate('/otp-verification/' + resp.data.user_id);
            }).catch(err => {
                setIsSubmitting(false);
                setErrorMsg(getErrorMsg(err));
                // Log error with stack trace
                logger.error('Registration failed', err);
            });
        },
    });

    return (
        <>
            { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            <Container component="main" maxWidth="sm">
                <Paper elevation={10}>
                    <Box
                        padding={2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign Up
                        </Typography>
                        <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="name"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Name"
                                        autoFocus
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="mobile"
                                        label="Mobile"
                                        name="mobile"
                                        autoComplete="family-name"
                                        value={formik.values.mobile}
                                        onChange={formik.handleChange}
                                        error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                        helperText={formik.touched.mobile && formik.errors.mobile}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        type="password"
                                        id="confirmPassword"
                                        autoComplete="new-password"
                                        value={formik.values.confirmPassword}
                                        onChange={formik.handleChange}
                                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                    />
                                </Grid>
                            </Grid>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSubmitting &&
                                    <CircularProgress />
                                }
                                {!isSubmitting &&
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Sign Up
                                    </Button>
                                }
                            </Box>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link to="/login" variant="body2">
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </>
    );
}

export default Register;